import React from 'react';
import { ReactComponent as ThumbsUpIconSVG } from '../assets/icons/thumbs_up.svg';
import { ReactComponent as ThumbsDownIconSVG } from '../assets/icons/thumbs_down.svg';
import { ReactComponent as CopyIconSVG } from '../assets/icons/copy_icon.svg';
import { ReactComponent as CheckmarkIconSVG } from '../assets/icons/checkmark.svg';

export const LinkRenderer = props => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

export const ThumbsUpIcon = ({ className, onClick }) => (
  <ThumbsUpIconSVG className={className} onClick={onClick} />
);

export const ThumbsDownIcon = ({ className, onClick }) => (
  <ThumbsDownIconSVG className={className} onClick={onClick} />
);

export const CopyIcon = ({ className, onClick }) => (
  <CopyIconSVG className={className} onClick={onClick} />
);

export const CheckmarkIcon = ({ className }) => (
  <CheckmarkIconSVG className={className} />
);
