const sasUrlCache = new Map(); // Cache pro ukládání načtených SAS URL

export const fetchSasUrl = async (documentName) => {
  if (sasUrlCache.has(documentName)) {
    return sasUrlCache.get(documentName);
  }

  const response = await fetch('https://komoradoc.azurewebsites.net/api/BlobSasTokenGenerator?', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ documentName }),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch SAS URL');
  }

  const sasUrl = await response.text();
  sasUrlCache.set(documentName, sasUrl);
  return sasUrl;
};

export const updateAssistantMessage = async (message) => {
  const docRegex = /\[(.+?)\]\[source(.+?)source\]/g;
  let updatedMessage = message.text;
  const matches = [];

  let match;
  while ((match = docRegex.exec(message.text)) !== null) {
    matches.push(match);
  }

  for (const match of matches.reverse()) {
    try {
      const displayName = match[1];
      const documentName = match[2];
      const sasUrl = await fetchSasUrl(documentName);
      const markdownLink = `[${displayName}](${sasUrl})`;
      updatedMessage = updatedMessage.substring(0, match.index) +
                       markdownLink +
                       updatedMessage.substring(match.index + match[0].length);
    } catch (error) {
      console.error('Error fetching SAS URL:', error);
    }
  }

  return { ...message, text: updatedMessage };
};
