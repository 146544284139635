import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { shuffle } from './utils.js';
import { updateAssistantMessage } from './helpers.js';
import { sendFeedbackToServer } from './feedback.js';
import { LinkRenderer, ThumbsUpIcon, ThumbsDownIcon, CopyIcon, CheckmarkIcon } from './components/Icons.js';
import bubbleTexts from './bubbletexts.js';
import './App.css';
import { v4 as uuidv4 } from 'uuid'; // Import the UUID package

const Chat = () => {
  const { conversationId } = useParams();
  const [query, setQuery] = useState('');
  const [messages, setMessages] = useState([]);
  const [temporaryAssistantMessage, setTemporaryAssistantMessage] = useState('');
  const [isBubbleClick, setIsBubbleClick] = useState(false);
  const [bubbles, setBubbles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTypingComplete, setIsTypingComplete] = useState(true); // Set to true initially for loading previous conversations

  useEffect(() => {
    setBubbles(shuffle(bubbleTexts).slice(0, 3));
  }, []);

  const messagesContainerRef = useRef(null);
  const userHasScrolledUp = useRef(false);

  const handleUserScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = messagesContainerRef.current;
    userHasScrolledUp.current = scrollHeight - (scrollTop + clientHeight) > 30;
  };

  const scrollToBottom = () => {
    if (!userHasScrolledUp.current) {
      messagesContainerRef.current?.scrollTo(0, messagesContainerRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, temporaryAssistantMessage]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleBubbleClick = (bubbleText) => {
    setIsBubbleClick(true);
    setQuery(bubbleText);
  };

  useEffect(() => {
    if (isBubbleClick) {
      handleSubmit();
      setIsBubbleClick(false);
    }
  }, [query, isBubbleClick]);

  const fetchAndUpdateMessages = async (messages) => {
    const updatedMessages = await Promise.all(messages.map(async (message) => {
      if (message.sender === 'Assistant') {
        return updateAssistantMessage(message);
      }
      return message;
    }));
    setMessages(updatedMessages);
    setIsTypingComplete(true); // Set typing complete after updating messages
  };

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await fetch(`https://serverkomora.azurewebsites.net/chat/${conversationId}`);
        if (response.ok) {
          const chatHistory = await response.json();
          if (chatHistory.messages && chatHistory.messages.length > 0) {
            await fetchAndUpdateMessages(chatHistory.messages);
          } else {
            // If there are no messages, set the initial message
            const initialMessage = {
              id: uuidv4(),
              sender: 'Assistant',
              text: 'Prohledávám obsah záložky AML v menu „Průvodce profesí" a „Předpisy Komory". Mým úkolem je poskytnout základní informace ohledně dotazů k AML problematice. Jsem podpůrným nástrojem k analýze dokumentů, nemohu však převzít konečnou odpovědnost za závěr, který uživatel učiní.',
              showCheckmark: false,
              thumbUpClicked: false,
              thumbDownClicked: false
            };
            setMessages([initialMessage]);
            // Send the initial message to the backend
            await sendInitialMessageToBackend(conversationId, initialMessage);
          }
        } else if (response.status === 404) {
          console.log('Conversation not found, initializing new conversation.');
          // Set the initial message for new conversations
          const initialMessage = {
            id: uuidv4(),
            sender: 'Assistant',
            text: 'Prohledávám obsah záložky AML v menu „Průvodce profesí" a „Předpisy Komory". Mým úkolem je poskytnout základní informace ohledně dotazů k AML problematice. Jsem podpůrným nástrojem k analýze dokumentů, nemohu však převzít konečnou odpovědnost za závěr, který uživatel učiní.',
            showCheckmark: false,
            thumbUpClicked: false,
            thumbDownClicked: false
          };
          setMessages([initialMessage]);
          // Send the initial message to the backend
          await sendInitialMessageToBackend(conversationId, initialMessage);
        } else {
          console.error('Failed to fetch chat history:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    };
    fetchChatHistory();
  }, [conversationId]);

  // New function to send the initial message to the backend
async function sendInitialMessageToBackend(conversationId, message) {
  try {
    await fetch(`https://serverkomora.azurewebsites.net/chat/${conversationId}/initial`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message }),
    });
  } catch (error) {
    console.error('Error sending initial message to the backend:', error);
  }
}

  const handleSubmit = async () => {
    if (query.trim() !== '') {
      const userMessage = { id: uuidv4(), sender: 'User', text: query, showCheckmark: false };
      setMessages(messages => [...messages, userMessage]);

      setIsLoading(true);
      setIsTypingComplete(false); // Set typing complete to false when a new message is being processed
      let accumulatedContent = '';
      let questionsForBubbles = [];

      const SEARCH_URL = 'https://serverkomora.azurewebsites.net/query';

      try {
        const response = await fetch(SEARCH_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ conversationId, query, messageId: userMessage.id }),
        });

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        if (reader) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              const updatedMessage = await updateAssistantMessage({ text: accumulatedContent });
              const assistantMessage = { id: uuidv4(), sender: 'Assistant', text: updatedMessage.text, showCheckmark: false, thumbUpClicked: false, thumbDownClicked: false };
              setMessages(messages => [...messages, assistantMessage]);

              if (questionsForBubbles.length) {
                setBubbles(questionsForBubbles);
              }

              setTemporaryAssistantMessage('');
              setIsLoading(false);
              setIsTypingComplete(true);

              // Fetch the updated chat history to ensure synchronization
              const updatedChatResponse = await fetch(`https://serverkomora.azurewebsites.net/chat/${conversationId}`);
              const updatedChatHistory = await updatedChatResponse.json();
              await fetchAndUpdateMessages(updatedChatHistory.messages || []);

              break;
            }
            setIsLoading(false);
            const chunkText = decoder.decode(value, { stream: true });
            const lines = chunkText.split('\n');
            for (let line of lines) {
              if (line.startsWith('data:')) {
                try {
                  if (line.startsWith('data: Questions:')) {
                    const questionsText = line.substring('data: Questions:'.length).trim();
                    const newQuestions = questionsText.split(', ');
                    questionsForBubbles.push(...newQuestions);
                  } else {
                    const chunkData = JSON.parse(line.substring(5));
                    const content = chunkData.text;
                    if (content) {
                      accumulatedContent += content;
                      setTemporaryAssistantMessage(accumulatedContent);
                    }
                  }
                } catch (parseError) {
                  console.error('Error parsing chunk:', parseError);
                }
              } else if (line.trim() === "[DONE]") {
                break;
              }
            }
          }
        }
      } catch (error) {
        console.error('Error while fetching response:', error);
        setMessages(messages => [...messages, { sender: 'AI', text: 'Sorry, there was an error processing your request.', showCheckmark: false, thumbUpClicked: false, thumbDownClicked: false }]);
        setIsLoading(false);
        setIsTypingComplete(true);
      }

      setQuery('');
    }
  };

  const handleCopyClick = (index) => {
    const messageElement = document.querySelectorAll('.message')[index];
    const copyContainer = messageElement.cloneNode(true);

    // Remove the tooltip text from the cloned node
    const tooltip = copyContainer.querySelector('.tooltip-text');
    if (tooltip) {
      tooltip.remove();
    }

    // Create a temporary element to hold the HTML
    const tempElement = document.createElement('div');
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    tempElement.appendChild(copyContainer);
    document.body.appendChild(tempElement);

    // Select the content of the temporary element
    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // Copy to clipboard
    document.execCommand('copy');

    // Cleanup
    document.body.removeChild(tempElement);
    selection.removeAllRanges();

    // Show the checkmark icon
    setMessages(messages => {
      const updatedMessages = [...messages];
      updatedMessages[index].showCheckmark = true;
      return updatedMessages;
    });
    setTimeout(() => {
      setMessages(messages => {
        const updatedMessages = [...messages];
        updatedMessages[index].showCheckmark = false;
        return updatedMessages;
      });
    }, 3000);
  };

  const handleThumbUpClick = (index) => {
    setMessages(messages => {
      const updatedMessages = messages.map((message, idx) => {
        if (idx === index && message.sender === 'Assistant') {
          const newThumbUpClicked = !message.thumbUpClicked;
          const newThumbDownClicked = false;
          sendFeedbackToServer(conversationId, message.id, newThumbUpClicked, newThumbDownClicked);
          updateMessageFeedback(conversationId, message.id, newThumbUpClicked, newThumbDownClicked); // Update feedback in the database
          return {
            ...message,
            thumbUpClicked: newThumbUpClicked,
            thumbDownClicked: newThumbDownClicked, // Deactivate thumb down when thumb up is clicked
          };
        }
        return message;
      });
      return updatedMessages;
    });
  };

  const handleThumbDownClick = (index) => {
    setMessages(messages => {
      const updatedMessages = messages.map((message, idx) => {
        if (idx === index && message.sender === 'Assistant') {
          const newThumbDownClicked = !message.thumbDownClicked;
          const newThumbUpClicked = false;
          sendFeedbackToServer(conversationId, message.id, newThumbUpClicked, newThumbDownClicked);
          updateMessageFeedback(conversationId, message.id, newThumbUpClicked, newThumbDownClicked); // Update feedback in the database
          return {
            ...message,
            thumbDownClicked: newThumbDownClicked,
            thumbUpClicked: newThumbUpClicked, // Deactivate thumb up when thumb down is clicked
          };
        }
        return message;
      });
      return updatedMessages;
    });
  };

  useEffect(() => {
    console.log('Messages state updated:', messages);
  }, [messages]);

  return (
    <div className="app">
      <div className="messages-container" ref={messagesContainerRef} onScroll={handleUserScroll}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            <ReactMarkdown components={{ a: LinkRenderer }} remarkPlugins={[gfm]}>
              {message.text}
            </ReactMarkdown>
            {message.sender === 'Assistant' && (
              <div className="icon-container">
                <ThumbsUpIcon
                  className={`thumbs-up-icon ${message.thumbUpClicked ? 'clicked' : ''}`}
                  onClick={() => handleThumbUpClick(index)}
                />
                <ThumbsDownIcon
                  className={`thumbs-down-icon ${message.thumbDownClicked ? 'clicked' : ''}`}
                  onClick={() => handleThumbDownClick(index)}
                />
                {!message.showCheckmark ? (
                  <div className="copy-container">
                    <CopyIcon className="copy-icon" onClick={() => handleCopyClick(index)} />
                    <span className="tooltip-text">zkopírovat</span>
                  </div>
                ) : (
                  <CheckmarkIcon className="checkmark-icon transition-icon" />
                )}
              </div>
            )}
          </div>
        ))}

        {isLoading && (
          <div className="message Assistant">
            <div className="loader">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot"></div>
            </div>
          </div>
        )}

        {temporaryAssistantMessage && (
          <div className="message Assistant">
            <ReactMarkdown components={{ a: LinkRenderer }} remarkPlugins={[gfm]}>
              {temporaryAssistantMessage}
            </ReactMarkdown>
          </div>
        )}
      </div>
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Napište Vaší otázku..."
          value={query}
          onChange={handleInputChange}
          onKeyPress={e => { if (e.key === 'Enter') handleSubmit(); }}
        />
        <button className="search-button" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

const RedirectToNewChat = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const conversationId = uuidv4(); // Generate a new UUID
    navigate(`/chat/${conversationId}`);
  }, [navigate]);

  return null;
};

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<RedirectToNewChat />} />
      <Route path="/chat/:conversationId" element={<Chat />} />
    </Routes>
  </Router>
);

export default App;

async function updateMessageFeedback(conversationId, messageId, thumbUpClicked, thumbDownClicked) {
  try {
    await fetch(`https://serverkomora.azurewebsites.net/feedback`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ conversationId, messageId, thumbUpClicked, thumbDownClicked }),
    });
  } catch (error) {
    console.error('Error updating feedback in the database:', error);
  }
}
