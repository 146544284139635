// feedback.js
export const sendFeedbackToServer = async (conversationId, messageId, thumbUpClicked, thumbDownClicked) => {
  const FEEDBACK_URL = 'https://serverkomora.azurewebsites.net/feedback';
  try {
    await fetch(FEEDBACK_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ conversationId, messageId, thumbUpClicked, thumbDownClicked }),
    });
  } catch (error) {
    console.error('Error sending feedback:', error);
  }
};
